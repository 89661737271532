import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/Layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path:'/test',
    name:'test',
    component: (resolve) => require(['@/views/test'], resolve)
  },
  {
    path:'/image3d/:id',
    name:'image3d',
    component: (resolve) => require(['@/views/product/image3d'], resolve)
  },
  {
    path:'/inews/:id',
    name:'inews',
    component: (resolve) => require(['@/views/iphone/new'], resolve)
  },
  {
    path:'/iproject/:id',
    name:'iproject',
    component: (resolve) => require(['@/views/iphone/project'], resolve)
  },
  {
    path:'/xieyi',
    name:'xieyi',
    component: (resolve) => require(['@/views/iphone/xieyi'], resolve)
  },
  {
    path:'/yinsi',
    name:'yinsi',
    component: (resolve) => require(['@/views/iphone/yinsi'], resolve)
  },
  {
    path:'/fenxiang',
    name:'fenxiang',
    component: (resolve) => require(['@/views/iphone/fenxiang'], resolve)
  },
  {
    path:'/brochure',
    name:'brochure',
    component: (resolve) => require(['@/views/iphone/brochure'], resolve)
  },
  {
    path:'/survey',
    name:'survey',
    component: (resolve) => require(['@/views/survey/index'], resolve)
  },
  {
    path: "/",
    name: 'Layout',
    component: Layout,
    redirect: "/index",
    children: [
      {
        path: 'login',
        name: 'login',
        component: (resolve) => require(['@/views/login'], resolve)
      },
      {
        path: 'reg',
        name: 'reg',
        component: (resolve) => require(['@/views/user/reg'], resolve)
      },
      {
        path: 'forget',
        name: 'forget',
        component: (resolve) => require(['@/views/user/forget'], resolve)
      },
      {
        path: 'index',
        name: 'index',
        component: (resolve) => require(['@/views/index'], resolve)
      },
      {
        path: 'energy',
        name: 'energy',
        component: (resolve) => require(['@/views/energy/index'], resolve)
      },
      {
        path: 'productinfo',
        name: 'productinfo',
        component: (resolve) => require(['@/views/product/productinfo'], resolve)
      },
      {
        path: 'product/:id',
        name: 'product',
        component: (resolve) => require(['@/views/product/product'], resolve)
      },
      {
        path: 'contrast',
        name: 'contrast',
        component: (resolve) => require(['@/views/product/canshuduibi'], resolve)
      },
      {
        path: 'scheme/:id',
        name: 'scheme',
        component: (resolve) => require(['@/views/scheme/scheme'], resolve)
      },
      {
        path: 'schemeinfo',
        name: 'schemeinfo',
        component: (resolve) => require(['@/views/scheme/schemeinfo'], resolve)
      },
      {
        path: 'applicationcase',
        name: 'applicationcase',
        component: (resolve) => require(['@/views/project/project'], resolve)
      },
      {
        path: 'projectinfo/:id',
        name: 'projectinfo/:id',
        component: (resolve) => require(['@/views/project/projectinfo'], resolve)
      },
      {
        path: 'technical',
        name: 'technical',
        component: (resolve) => require(['@/views/jishu/index'], resolve)
      },
      {
        path: 'datadownload',
        name: 'datadownload',
        component: (resolve) => require(['@/views/jishu/ziliao'], resolve)
      },
      {
        path: 'productcatalogue',
        name: 'productcatalogue',
        component: (resolve) => require(['@/views/jishu/xuanxing'], resolve)
      },
      {
        path: 'ecucode',
        name: 'ecucode',
        component: (resolve) => require(['@/views/jishu/ecu'], resolve)
      },
      {
        path: 'faqs',
        name: 'faqs',
        component: (resolve) => require(['@/views/jishu/wenti'], resolve)
      },
      {
        path: 'feedback',
        name: 'feedback',
        component: (resolve) => require(['@/views/jishu/fankui'], resolve)
      },
      {
        path: 'contactus',
        name: 'contactus',
        component: (resolve) => require(['@/views/lianxi/index'], resolve)
      },
      {
        path: 'aboutus',
        name: 'aboutus',
        component: (resolve) => require(['@/views/guanyu/index'], resolve)
      },
      {
        path: 'hr',
        name: 'hr',
        component: (resolve) => require(['@/views/guanyu/jiaru'], resolve)
      },
      {
        path: 'trademark',
        name: 'trademark',
        component: (resolve) => require(['@/views/guanyu/biaoshi'], resolve)
      },
      {
        path: 'honor',
        name: 'honor',
        component: (resolve) => require(['@/views/guanyu/rongyu'], resolve)
      },
      {
        path: 'news',
        name: 'news',
        component: (resolve) => require(['@/views/guanyu/news'], resolve)
      },
      {
        path: 'news_info/:id',
        name: 'news_info/:id',
        component: (resolve) => require(['@/views/guanyu/news_info'], resolve)
      },
      {
        path: 'search',
        name: 'search',
        component: (resolve) => require(['@/views/search/index'], resolve)
      },
      {
        path: 'personal',
        name: 'personal',
        component: (resolve) => require(['@/views/user/personal'], resolve)
      },
      {
        path: 'laboratory',
        name: 'laboratory',
        component: (resolve) => require(['@/views/laboratory/index'], resolve)
      },
      {
        path: 'escr',
        name: 'escr',
        component: (resolve) => require(['@/views/laboratory/escr'], resolve)
      },
      {
        path: 'pfmea',
        name: 'pfmea',
        component: (resolve) => require(['@/views/laboratory/pfmea'], resolve)
      },
      {
        path: 'emc',
        name: 'emc',
        component: (resolve) => require(['@/views/laboratory/emc'], resolve)
      },
      {
        path: 'develop',
        name: 'develop',
        component: (resolve) => require(['@/views/guanyu/develop'], resolve)
      },
      {
        path:'/xieyis',
        name:'xieyis',
        component: (resolve) => require(['@/views/xieyis'], resolve)
      },
      {
        path:'/investor',
        name:'investor',
        component: (resolve) => require(['@/views/investor'], resolve)
      }
    ]
  }
]
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  mode: 'history', // require service support
  routes
})
router.beforeEach((to, from, next) => {
  if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
      next()
  }else if(to.matched.length === 0){
      next('/index')
  }else{
      sessionStorage.setItem('active',to.name)
      next();
  }
});

export default router
