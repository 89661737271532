import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/styles/index.scss' // global css
import ElementUI from 'element-ui';
import ElementLocale from 'element-ui/lib/locale';
import messages from './common/lang';
import 'element-ui/lib/theme-chalk/index.css';
import echart from "echarts"
import VueI18n from 'vue-i18n'
import jsCookie from 'js-cookie'
import MetaInfo from 'vue-meta-info'

Vue.use(MetaInfo)
Vue.prototype.$echarts = echart
Vue.prototype.$cookie = jsCookie;
Vue.config.productionTip = false
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'zh',
  messages
});

Vue.use(ElementUI, ElementLocale.i18n((key, value) => i18n.t(key, value)));

router.afterEach(() => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
})
new Vue({
  router,
  i18n,
  render: h => h(App),
  //添加到这里,这里的render-event和vue.config.js里面的renderAfterDocumentEvent配置名称一致
  mounted() {
    //document.dispatchEvent(new Event('render-event'))
    //document.dispatchEvent(new Event('custom-render-trigger'))
  }
}).$mount('#app')